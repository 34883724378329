<script setup>
import { useTemplateRef } from 'vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: undefined,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  inputWidth: {
    type: String,
    default: null,
  },
  largeThumb: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    required: true,
  },
  name: {
    type: String,
    default: '',
  },
  step: {
    type: Number,
    required: true,
  },
  translateX: {
    type: String,
    default: null,
  },
  translateY: {
    type: String,
    default: null,
  },
  modelValue: {
    type: Number,
    required: true,
  },
  useBrandColor: {
    type: Boolean,
    default: false,
  },
  vertical: {
    type: Boolean,
    default: false,
  },
});

const $emit = defineEmits(['update:modelValue']);
const $style = useCssModule();

const { isChrome, isFirefox } = useDevice();
const rangeInput = useTemplateRef('rangeInput');

const inputClass = computed(() => {
  return [
    props.fullWidth ? 'tw-w-full' : null,
    isFirefox ? 'tw-my-1.5' : 'tw-my-3',
    props.disabled ? 'tw-opacity-50 tw-pointer-events-none' : null,
    'focus-visible:tw-outline-none',
    $style.inputRange,
    props.vertical ? 'tw-origin-top-left' : null,
    props.largeThumb ? $style.largeThumb : null,
    props.useBrandColor ? $style.useBrandColor : null,
  ];
});

/**
 * Rotate the <input type="range"> itself when rendering a vertical slider. Rotating the parent
 * element breaks the ability to drag the thumb on mobile devices, but rotating the slider
 * itself, doesn't!
 *
 * Vertical sliders have to be sized and rotated by the parent component to fit properly in the
 * allocated space. So the inputWidth, translateX, and translateY should be specified by the
 * parent when rendering a vertical slider.
 *
 * @see https://dev.to/konnorrogers/creating-a-vertical-slider-using-input-typerange-1pen
 * @see https://stackoverflow.com/questions/76082303/vertical-slider-cant-be-dragged-to-move-on-mobile
 */
const inputStyle = computed(() => {
  if (props.vertical) {
    return {
      transform: `rotate(270deg) translate(${props.translateX}, ${props.translateY})`,
      width: props.inputWidth,
    };
  }

  return null;
});

/**
 * Use JavaScript to set selected runnable track color, i.e. the color of the track to the left
 * of the thumb, to saturated blue in Chrome when the useBrandColor prop is true. In FireFox
 * the ::-moz-range-progress element is used instead. Note that this could also be done with CSS
 * but the solution is much more complex (see last link).
 *
 * @see
 * - https://stackoverflow.com/questions/65738788/input-range-slider-progress-for-chrome-browser
 * - https://codepen.io/duplich/pen/qjYQEZ
 * - https://codepen.io/ShadowShahriar/pen/zYPPYrQ
 */
const setInputBackground = (value) => {
  if (isChrome && props.useBrandColor) {
    const percent = Math.round(value / (props.max - props.min) * 100);
    rangeInput.value.style.background = `linear-gradient(to right, var(--saturated-blue) 0%, var(--saturated-blue) ${percent}%, var(--text-light) ${percent}%, var(--text-light) 100%)`;
  }
};

const handleInput = (event) => {
  $emit('update:modelValue', Number(event.target.value));
  setInputBackground(event.target.value);
};

onMounted(() => {
  setInputBackground(props.modelValue);
});

watch(() => props.useBrandColor, () => setInputBackground(props.modelValue));
</script>

<template>
  <input
    ref="rangeInput"
    :class="inputClass"
    :disabled="disabled"
    :max="max"
    :min="min"
    :name="name"
    :step="step"
    :style="inputStyle"
    type="range"
    :value="modelValue"
    @input="handleInput"
  >
</template>

<style module>
/*
 * The selectors below must be repeated, they cannot be grouped together. This is because the
 * browser will stop parsing the selectors when it doesn't understand one, and since these
 * selectors are all browser specific that would lead to not applying the styles correctly.
 */

.inputRange {
  appearance: none;
  background-color: inherit;
}

.inputRange::-webkit-slider-runnable-track {
  background: var(--text-dark);
  border-color: var(--text-dark);
  cursor: pointer;
  height: 0.25rem;
}

.inputRange.useBrandColor::-webkit-slider-runnable-track {
  background: unset;
  border-color: unset;
}

.inputRange[disabled]::-webkit-slider-runnable-track {
  cursor: not-allowed;
}

.inputRange::-moz-range-track {
  background: var(--text-dark);
  border-color: var(--text-dark);
  cursor: pointer;
  height: 0.25rem;
}

.inputRange.useBrandColor::-moz-range-track {
  background: var(--text-light);
  border-color: var(--text-light);
}

.inputRange[disabled]::-moz-range-track {
  cursor: not-allowed;
}

.inputRange.useBrandColor::-moz-range-progress {
  background-color: var(--saturated-blue);
  height: 0.25rem;
}

.inputRange::-webkit-slider-thumb {
  appearance: none;
  background: var(--text-dark);
  border-color: var(--text-dark);
  border-radius: 0.5rem;
  cursor: grab;
  height: 1rem;
  margin-top: -0.4rem;
  width: 1rem;
}

.inputRange.useBrandColor::-webkit-slider-thumb {
  background: var(--saturated-blue);
  border-color: var(--saturated-blue);
}

.inputRange[disabled]::-webkit-slider-thumb {
  cursor: not-allowed;
}

.inputRange::-moz-range-thumb {
  background: var(--text-dark);
  border-color: var(--text-dark);
  border-radius: 0.45rem;
  cursor: grab;
  height: 0.9rem;
  margin-top: -0.4rem;
  width: 0.9rem;
}

.inputRange.useBrandColor::-moz-range-thumb {
  background: var(--saturated-blue);
  border-color: var(--saturated-blue);
}

.inputRange[disabled]::-moz-range-thumb {
  cursor: not-allowed;
}

.largeThumb::-webkit-slider-thumb {
  border-radius: 0.75rem;
  height: 1.5rem;
  margin-top: -0.6rem;
  width: 1.5rem;
}

.largeThumb::-moz-range-thumb {
  border-radius: 0.8rem;
  height: 1.6rem;
  margin-top: -0.6rem;
  width: 1.6rem;
}
</style>
